<template>
  <div class="Console">
    <el-row :gutter="20">
      <el-col :span="12"
        ><div class="blockWrap">
          <div style="height: 250px">
            <div style="border-bottom: 1px solid #eee; padding-bottom: 20px">
              <i
                class="el-icon-user-solid"
                style="font-size: 30px; color: #f39805; margin-right: 20px"
              ></i>
              <span style="font-size: 30px">{{ userData.companyName }}</span>
            </div>
            <div class="mt20 big">userId: {{ userData.userId }}</div>
            <div class="mt20 big">appId: {{ userData.appId }}</div>
            <div class="mt20 big">appSecret: {{ userData.appSecret }}</div>
          </div>
        </div></el-col
      >
      <el-col :span="12">
        <div class="blockWrap">
          <div style="height: 250px">
            <div style="border-bottom: 1px solid #eee; padding-bottom: 20px">
              <i
                class="el-icon-connection"
                style="font-size: 30px; color: #f39805; margin-right: 20px"
              ></i>
              <span style="font-size: 30px">设备接入权限</span>
            </div>
            <div class="mt20 big">
              <i class="el-icon-warning-outline"></i>
              扬尘噪音:
              <i
                :class="
                  userData.devicePermission.dust == 1
                    ? 'el-icon-check green'
                    : 'el-icon-close red'
                "
              ></i>
              <span
                :class="userData.devicePermission.dust == 1 ? 'green' : 'red'"
                >{{
                  userData.devicePermission.dust == 1 ? "允许" : "不允许"
                }}</span
              >
            </div>
            <div class="mt20 big">
              <i class="el-icon-warning-outline"></i>
              塔式起重机:
              <i
                :class="
                  userData.devicePermission.tower == 1
                    ? 'el-icon-check green'
                    : 'el-icon-close red'
                "
              ></i>
              <span
                :class="userData.devicePermission.tower == 1 ? 'green' : 'red'"
                >{{
                  userData.devicePermission.tower == 1 ? "允许" : "不允许"
                }}</span
              >
            </div>
            <div class="mt20 big">
              <i class="el-icon-warning-outline"></i>
              施工升降机:
              <i
                :class="
                  userData.devicePermission.elevator == 1
                    ? 'el-icon-check green'
                    : 'el-icon-close red'
                "
              ></i>
              <span
                :class="
                  userData.devicePermission.elevator == 1 ? 'green' : 'red'
                "
                >{{
                  userData.devicePermission.elevator == 1 ? "允许" : "不允许"
                }}</span
              >
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="24">
        <div class="blockWrap">
          <div style="border-bottom: 1px solid #eee; padding-bottom: 20px">
            <i
              class="el-icon-odometer"
              style="font-size: 30px; color: #f39805; margin-right: 20px"
            ></i>
            <span style="font-size: 30px">数据上传情况</span>
          </div>

          <el-table
            :data="listData"
            border
            class="list"
            header-row-class-name="tableHeader"
          >
            <el-table-column
              type="index"
              width="50"
              label="序号"
            ></el-table-column>
            <el-table-column prop="deviceType" label="设备类型"
              >
              <template slot-scope="scope">
                <span v-if="scope.row.deviceType == 1">扬尘噪音</span>
                <span v-if="scope.row.deviceType == 2">塔式起重机</span>
                <span v-if="scope.row.deviceType == 3">施工升降机</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="success"
              label="数据上传成功次数"
            ></el-table-column>
            <el-table-column
              prop="failure"
              label="数据上传失败次数"
            ></el-table-column>
            <el-table-column
              prop="uploadStatus"
              width="100"
              label="状态"
            ></el-table-column>
            <el-table-column
              prop="uploadTime"
              label="最新上传时间"
            ></el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
    
<style lang="less" scoped>
.Console {
  padding: 20px;

  .blockWrap {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
  }
  .mt20 {
    margin-top: 20px;
  }
  .big {
    font-size: 18px;
  }
  .green {
    color: #00740a;
    font-weight: 700;
  }
  .red {
    color: #f00;
    font-weight: 300;
  }
  .tableHeader {
    background-color: #999;
  }
}
</style>
    
<script>
export default {
  name: "Console",
  components: {},
  data() {
    return {
      userData: {
        id: null,
        userId: null,
        appId: null,
        appSecret: null,
        permission: null,
        devicePermission: {
          dust: null,
          tower: null,
          elevator: null,
        },
      },
      listData: [],
    };
  },
  computed: {
    userName: function () {
      return localStorage.getItem("userName");
    },
  },
  mounted() {
    this.onGetUserData();
    this.onList();
  },
  methods: {
    goto: function (path) {
      this.$router.push({
        name: path,
      });
    },
    // 获取用户信息
    onGetUserData: function () {
      var that = this;
      var data = {};
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-iot/interconnection/detail",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.userData = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取列表数据
    onList: function () {
      var that = this;
      var data = {
        deviceType: "",
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-iot/device/dataUploadStatusToday",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.listData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
    